<script setup lang="ts">
import { capitalizeFirstLetter } from "~/common/helpers"

const selected = defineModel("selected", { required: true })
const props = defineProps<{
    keyName?: string
    keyLabel?: string
    resource: string
    searchPlaceholder?: string
    multiple?: boolean
    useStore?: boolean
    label: string
    errors?: string[] | string
    withCustomIcon?: boolean
    filter?: boolean
    withoutFormat?: boolean
}>()

const valueObj = computed(() => props.keyName || "id")
const labelObj = computed(() => props.keyLabel || "name")

const brandSelect = ref()

const emits = defineEmits(["update:errors"])

const { data, loading, get, setUrl } = useOFetchCustom(props.resource)

const options = ref<string[]>([])

watch(
    () => props.resource,
    (newValue) => {
        setUrl(newValue)
        get(undefined, {
            onSuccess: () => {
                options.value = getItems()
            },
        })
    },
    {
        immediate: true,
        deep: true,
    }
)

function getItems() {
    let items = []

    if (
        data.value &&
        data.value.data &&
        Array.isArray(data.value.data?.data) &&
        data.value.data.data.length > 0
    ) {
        items = data.value.data.data
    } else if (
        data.value &&
        data.value.data &&
        Array.isArray(data.value.data) &&
        data.value.data.length > 0
    ) {
        items = data.value.data
    } else if (
        data.value &&
        data.value.data &&
        Array.isArray(data.value.data.items)
    ) {
        items = data.value.data.items
    }

    return items.map((item: any) => {
        if (typeof item === "string") {
            return props.withoutFormat ? item : capitalizeFirstLetter(item)
        }

        const i = {
            ...item,
            [labelObj.value]: props.withoutFormat
                ? item[labelObj.value]
                : capitalizeFirstLetter(item[labelObj.value]),
        }
        return i
    })
}

function filterFn(val: string, update: any) {
    if (val == "") {
        update(() => {
            options.value = getItems()
        })
    } else {
        update(() => {
            const needle = val.toLowerCase()
            options.value = getItems().filter(
                (c) => c.name.toLowerCase().indexOf(needle) > -1
            )
        })
    }
}

const showDetails = ref(false)

const toggleDetails = () => {
    showDetails.value = !showDetails.value
    return showDetails.value
}

const onClick = () => {
    if (showDetails.value) {
        brandSelect.value?.hide()
    } else {
        brandSelect.value?.show()
    }
    toggleDetails()
}
</script>

<template>
    <Select
        ref="brandSelect"
        v-model="selected"
        color="primary"
        :label="label"
        :error="errors != null"
        :options="options"
        :option-label="labelObj"
        :option-value="valueObj"
        :multiple="multiple"
        clearable
        transition-show="jump-up"
        transition-hide="jump-down"
        @update:errors="emits('update:errors')"
        :loading="loading"
        :use-input="!!filter"
        @filter="filterFn"
    >
        <template #label>
            <slot name="label" />
        </template>
        <template #after v-if="props.withCustomIcon">
            <div>
                <q-icon
                    class="tw-cursor-pointer"
                    name="fa-solid fa-angle-up"
                    size="16px"
                    v-if="showDetails"
                    @click="onClick"
                />
                <q-icon
                    class="tw-cursor-pointer"
                    name="fa-solid fa-angle-down"
                    size="16px"
                    v-else
                    @click="onClick"
                />
            </div>
        </template>
    </Select>
</template>
