<script lang="ts" setup>
const props = defineProps<{
    store_id?: number
    api_endpoint?: string
    roles_endpoint?: string
    no_selection_message?: string
}>()

defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
    useDialogPluginComponent()

const owner_create_group = ref("")

const password = ref(true)
const password_confirmation = ref(true)

const formState = useForm<{
    store_role_id?: number
    user_id?: number
    user_name?: string
    user_lastname?: string
    user_email?: string
    user_phone?: string
    user_password?: string
    user_password_confirmation?: string
}>({
    store_role_id: null,
    user_id: null,
    user_name: null,
    user_lastname: null,
    user_email: null,
    user_phone: null,
    user_password: null,
    user_password_confirmation: null,
})

const change = (val: string) => {
    formState.clearErrors()
    if (val == "select_user") {
        formState.setData({
            store_role_id: null,
            user_name: null,
            user_lastname: null,
            user_email: null,
            user_phone: null,
            user_password: null,
            user_password_confirmation: null,
        })
    } else if (val == "new_user") {
        formState.setData({
            store_role_id: null,
            manager_id: null,
        })
    }
}

const getRolesEndpoint = computed(() => {
    if (props.roles_endpoint) {
        return props.roles_endpoint
    }
    return props.store_id ? `stores/${props.store_id}/roles` : "roles"
})

const getSubmitEndpoint = computed(() => {
    if (props.api_endpoint) {
        return props.api_endpoint
    }
    return props.store_id ? `stores/${props.store_id}/managers` : "managers"
})

const submit = () => {
    formState
        .post(getSubmitEndpoint.value)
        .then((res: any) => {
            onDialogOK()
        })
        .catch()
}

const cancel = () => {
    onDialogCancel()
}

// Named export for the component
const ManagerFormDialog = {}
// Default export
defineExpose(ManagerFormDialog)
</script>
<template>
    <q-dialog ref="dialogRef">
        <q-card class="!tw-max-w-[900px] tw-w-[900px]">
            <q-card-section>
                <div class="tw-text-xl">
                    {{ $t("stores.managers.new") }}
                </div>
            </q-card-section>
            <q-card-section>
                <div class="">
                    <div class="tw-flex tw-justify-center tw-gap-4">
                        <div class="tw-text-center tw-w-2/5">
                            <q-option-group
                                @update:model-value="(val) => change(val)"
                                type="radio"
                                :options="[
                                    {
                                        label: $t('stores.select_user'),
                                        value: 'select_user',
                                    },
                                    {
                                        label: $t('stores.new_user'),
                                        value: 'new_user',
                                    },
                                ]"
                                v-model="owner_create_group"
                            ></q-option-group>
                        </div>
                        <div class="tw-w-full">
                            <div
                                class="tw-w-full tw-text-center tw-flex tw-items-center tw-justify-center tw-h-full"
                                v-if="!owner_create_group"
                            >
                                <p class="tw-font-semibold">
                                    {{
                                        no_selection_message ??
                                        $t(
                                            "messages.select_owner_creation_method"
                                        )
                                    }}
                                </p>
                            </div>
                            <div
                                class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-5"
                                v-if="owner_create_group == 'select_user'"
                            >
                                <SelectApi
                                    v-model:selected="formState.user_id"
                                    :label="$t('fields.user')"
                                    clearable
                                    class="tw-w-full tw-mx-auto"
                                    resource="users/all"
                                    :errors="formState.errors.user_id"
                                    outlined
                                    required
                                    map-options
                                    :emit-value="true"
                                    @update:selected="
                                        (val) =>
                                            console.log(val, 'update selected')
                                    "
                                ></SelectApi>
                                <SelectApi
                                    v-model:selected="formState.store_role_id"
                                    :label="$t('fields.role')"
                                    clearable
                                    class="tw-w-full tw-mx-auto"
                                    :resource="getRolesEndpoint"
                                    :errors="formState.errors.store_role_id"
                                    outlined
                                    required
                                    key-name="id"
                                    key-label="name"
                                    map-options
                                    :emit-value="true"
                                ></SelectApi>
                            </div>
                            <div
                                v-if="owner_create_group == 'new_user'"
                                class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-5"
                            >
                                <TextInput
                                    v-model="formState.user_name"
                                    :label="$t('fields.name')"
                                    :errors="formState.errors.user_name"
                                    required
                                    outlined
                                />

                                <TextInput
                                    v-model="formState.user_lastname"
                                    :label="$t('fields.lastname')"
                                    :errors="formState.errors.user_lastname"
                                    required
                                    outlined
                                />

                                <TextInput
                                    v-model="formState.user_email"
                                    type="email"
                                    :label="$t('fields.email')"
                                    :errors="formState.errors.user_email"
                                    autocomplete="new-password"
                                    required
                                    outlined
                                />

                                <TextInput
                                    v-model="formState.user_phone"
                                    type="tel"
                                    :label="$t('fields.phone')"
                                    :errors="formState.errors.user_phone"
                                    required
                                    outlined
                                />

                                <SelectApi
                                    v-model:selected="formState.store_role_id"
                                    :label="$t('fields.role')"
                                    clearable
                                    class="tw-w-full tw-mx-auto"
                                    :resource="getRolesEndpoint"
                                    :errors="formState.errors.store_role_id"
                                    outlined
                                    required
                                    key-name="id"
                                    key-label="name"
                                    map-options
                                    :emit-value="true"
                                ></SelectApi>

                                <TextInput
                                    v-model="formState.user_password"
                                    :type="password ? 'password' : 'text'"
                                    :label="$t('fields.password')"
                                    :errors="formState.errors.user_password"
                                    autocomplete="new-password"
                                    required
                                    outlined
                                >
                                    <template v-slot:append>
                                        <q-icon
                                            :name="
                                                password
                                                    ? 'visibility_off'
                                                    : 'visibility'
                                            "
                                            class="tw-cursor-pointer"
                                            @click="password = !password"
                                            color="primary"
                                        />
                                    </template>
                                </TextInput>

                                <TextInput
                                    v-model="
                                        formState.user_password_confirmation
                                    "
                                    :type="
                                        password_confirmation
                                            ? 'password'
                                            : 'text'
                                    "
                                    :label="$t('fields.password_confirmation')"
                                    :errors="
                                        formState.errors
                                            .user_password_confirmation
                                    "
                                    required
                                    outlined
                                >
                                    <template v-slot:append>
                                        <q-icon
                                            :name="
                                                password_confirmation
                                                    ? 'visibility_off'
                                                    : 'visibility'
                                            "
                                            class="tw-cursor-pointer"
                                            @click="
                                                password_confirmation =
                                                    !password_confirmation
                                            "
                                            color="primary"
                                        />
                                    </template>
                                </TextInput>
                            </div>
                        </div>
                    </div>
                </div>
            </q-card-section>
            <q-card-actions align="right">
                <q-btn
                    flat
                    @click="cancel"
                    :label="$t('actions.cancel')"
                    color="primary"
                />
                <q-btn
                    flat
                    @click="submit"
                    :label="$t('actions.add')"
                    color="primary"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
