import type { User } from "~/models/User"

const checkPermissionMarketplace = (permission: string, user: User) => {
    return user.all_permissions.findIndex((p) => p == permission) != -1
}

const checkPermissionStore = (permission: string, user: User) => {
    return (
        user.stores_manager_of
            .flatMap((s) => s.permissions)
            .findIndex((p) => p == permission) != -1
    )
}

export function useCan() {
    const { user } = useSanctumAuth<User>()
    function can(
        permission: string[] | string,
        environment?: "store" | "marketplace"
    ): boolean {
        if (permission && user.value) {
            if (typeof permission == "string") {
                if (!environment) {
                    return (
                        checkPermissionMarketplace(permission, user.value) ||
                        checkPermissionStore(permission, user.value)
                    )
                }

                if (environment == "marketplace") {
                    return checkPermissionMarketplace(permission, user.value)
                }

                if (environment == "store") {
                    return checkPermissionStore(permission, user.value)
                }
            }

            for (const permissionElement of permission) {
                if (!environment) {
                    return (
                        checkPermissionMarketplace(
                            permissionElement,
                            user.value
                        ) || checkPermissionStore(permissionElement, user.value)
                    )
                }
                if (environment == "marketplace") {
                    return checkPermissionMarketplace(
                        permissionElement,
                        user.value
                    )
                }

                if (environment == "store") {
                    return checkPermissionStore(permissionElement, user.value)
                }
            }

            return false
        }

        return false
    }

    return can
}

export function useCanInStore() {
    const { user } = useSanctumAuth<User>()

    function canInStore(permission: string[] | string, store_id: number) {
        if (permission && user.value) {
            if (typeof permission == "string") {
                return (
                    user.value.stores_manager_of
                        .find((s) => s.id == store_id)
                        ?.permissions.findIndex((p) => p == permission) != -1
                )
            }

            return permission.every((p) =>
                user.value?.stores_manager_of
                    .find((s) => s.id == store_id)
                    ?.permissions.includes(p)
            )
        }
    }

    return canInStore
}
