
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as clientsFlwxm4lq96Meta } from "/app/pages/Admin/b2bauth/clients.vue?macro=true";
import { default as _91id_93SnzDCQ1cOGMeta } from "/app/pages/Admin/blogs/categories/[id].vue?macro=true";
import { default as createYRZECtAMNaMeta } from "/app/pages/Admin/blogs/categories/create.vue?macro=true";
import { default as indexQiYL4YKgzaMeta } from "/app/pages/Admin/blogs/categories/index.vue?macro=true";
import { default as _91id_93U2WZs7fGr8Meta } from "/app/pages/Admin/blogs/keywords/[id].vue?macro=true";
import { default as createLlPvADp5yRMeta } from "/app/pages/Admin/blogs/keywords/create.vue?macro=true";
import { default as indexAVX8f0bSdeMeta } from "/app/pages/Admin/blogs/keywords/index.vue?macro=true";
import { default as _91id_93EX7OeoOdp0Meta } from "/app/pages/Admin/blogs/posts/[id].vue?macro=true";
import { default as createefcvwkDmCHMeta } from "/app/pages/Admin/blogs/posts/create.vue?macro=true";
import { default as indexN09f59yln3Meta } from "/app/pages/Admin/blogs/posts/index.vue?macro=true";
import { default as _91id_93XdnlzqpHGSMeta } from "/app/pages/Admin/brands/[id].vue?macro=true";
import { default as createEPaShWGnvSMeta } from "/app/pages/Admin/brands/create.vue?macro=true";
import { default as indexQOmRV7n9r7Meta } from "/app/pages/Admin/brands/index.vue?macro=true";
import { default as indexstZ9jQDbVDMeta } from "/app/pages/Admin/Criterias/index.vue?macro=true";
import { default as indexFSIWPprlVlMeta } from "/app/pages/Admin/Dispatch-orders/index.vue?macro=true";
import { default as indexokFBc4IelZMeta } from "/app/pages/Admin/invoices/index.vue?macro=true";
import { default as indexVjRDbat5g2Meta } from "/app/pages/Admin/Managers/index.vue?macro=true";
import { default as _91id_93zjIayQjucRMeta } from "/app/pages/Admin/Offers/[id].vue?macro=true";
import { default as CreateAecd1lfXy0Meta } from "/app/pages/Admin/Offers/Create.vue?macro=true";
import { default as indexORYOOkfbB0Meta } from "/app/pages/Admin/Offers/index.vue?macro=true";
import { default as indexm448lcsZVZMeta } from "/app/pages/Admin/Orders/index.vue?macro=true";
import { default as index81ld4DjSEBMeta } from "/app/pages/Admin/Reviews/index.vue?macro=true";
import { default as _91id_93KEqMeWsWLkMeta } from "/app/pages/Admin/Roles/[id].vue?macro=true";
import { default as CreateUzbSGGdDqWMeta } from "/app/pages/Admin/Roles/Create.vue?macro=true";
import { default as indexLTcmbj3RapMeta } from "/app/pages/Admin/Roles/index.vue?macro=true";
import { default as CustomizationnLhrkJOvBDMeta } from "/app/pages/Admin/Settings/Customization.vue?macro=true";
import { default as GeneralsItpbOsixYkMeta } from "/app/pages/Admin/Settings/Generals.vue?macro=true";
import { default as editWNGxEqLYrtMeta } from "/app/pages/Admin/Stores/[id]/edit.vue?macro=true";
import { default as managers2p6ejRqgH9Meta } from "/app/pages/Admin/Stores/[id]/managers.vue?macro=true";
import { default as createMzJLJjgtvUMeta } from "/app/pages/Admin/Stores/create.vue?macro=true";
import { default as indexDejcw4kpqiMeta } from "/app/pages/Admin/Stores/index.vue?macro=true";
import { default as __91id_93liPgmXIUrWMeta } from "/app/pages/Admin/Stores/settings/_[id].vue?macro=true";
import { default as customizationsmIotyFUuBYMeta } from "/app/pages/Admin/Stores/settings/customizations.vue?macro=true";
import { default as notifications2Jr5UUnHMjMeta } from "/app/pages/Admin/Stores/settings/notifications.vue?macro=true";
import { default as credentialsTeOBXlbDJkMeta } from "/app/pages/Admin/stripe-proxy/credentials.vue?macro=true";
import { default as transactionsmrO5TgV3NuMeta } from "/app/pages/Admin/stripe-proxy/transactions.vue?macro=true";
import { default as showK0q5jFKNluMeta } from "/app/pages/Admin/Suborders/[suborder]/show.vue?macro=true";
import { default as indexko3TDLI7bkMeta } from "/app/pages/Admin/Suborders/index.vue?macro=true";
import { default as _91id_93vDNpOuaA6pMeta } from "/app/pages/Admin/Users/[id].vue?macro=true";
import { default as create9E3IcyrukmMeta } from "/app/pages/Admin/Users/create.vue?macro=true";
import { default as indexE5cTrmppiJMeta } from "/app/pages/Admin/Users/index.vue?macro=true";
import { default as ConfirmPasswordra4JnFDaTWMeta } from "/app/pages/Auth/ConfirmPassword.vue?macro=true";
import { default as ForgotPasswordCDue6KKurYMeta } from "/app/pages/Auth/ForgotPassword.vue?macro=true";
import { default as LoginekOdCTVoFyMeta } from "/app/pages/Auth/Login.vue?macro=true";
import { default as Registery2oeogxvmHMeta } from "/app/pages/Auth/Register.vue?macro=true";
import { default as ResetPasswordLAGYHoKH4rMeta } from "/app/pages/Auth/ResetPassword.vue?macro=true";
import { default as VerifyEmailErFhC3WKjYMeta } from "/app/pages/Auth/VerifyEmail.vue?macro=true";
import { default as _91blog_93SLRfK6x4QJMeta } from "/app/pages/blog/[blog].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as indexSZk2qNn8VwMeta } from "/app/pages/Cart/index.vue?macro=true";
import { default as _91id_93zd8C5QrYYyMeta } from "/app/pages/Categories/[id].vue?macro=true";
import { default as CreatecyfxlxalP2Meta } from "/app/pages/Categories/Create.vue?macro=true";
import { default as indexuVOdZoEgx5Meta } from "/app/pages/Categories/index.vue?macro=true";
import { default as indexS7GVjoY7GdMeta } from "/app/pages/Contact-Us/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as infoRUNMKy7QPmMeta } from "/app/pages/info.vue?macro=true";
import { default as _91InformationPage_93WZq69kjGVSMeta } from "/app/pages/information/[InformationPage].vue?macro=true";
import { default as indexOPPBcA3NliMeta } from "/app/pages/Offers/index.vue?macro=true";
import { default as redirect_45pageXQvaacDffdMeta } from "/app/pages/Orders/[order]/redirect-page.vue?macro=true";
import { default as showG9p1PawY1uMeta } from "/app/pages/Orders/[order]/show.vue?macro=true";
import { default as success_45pageKccXviSTeTMeta } from "/app/pages/Orders/[order]/success-page.vue?macro=true";
import { default as Create5R6EFUKi7cMeta } from "/app/pages/Orders/Create.vue?macro=true";
import { default as _91product_93fyC6SDu9TAMeta } from "/app/pages/Products/[product].vue?macro=true";
import { default as editJpj8E8UponMeta } from "/app/pages/Products/[slug]/edit.vue?macro=true";
import { default as AcceptReviewCvyZQh6en6Meta } from "/app/pages/Products/AcceptReview.vue?macro=true";
import { default as CreateDQA0mG28MSMeta } from "/app/pages/Products/Create.vue?macro=true";
import { default as indexTexopOjvruMeta } from "/app/pages/Products/index.vue?macro=true";
import { default as ReadReviewy71luuBhJBMeta } from "/app/pages/Products/ReadReview.vue?macro=true";
import { default as _91product_93OmygPy1iPjMeta } from "/app/pages/Products/Review/Read/[product].vue?macro=true";
import { default as _91product_937uvvlCEDhtMeta } from "/app/pages/Products/Review/Write/[product].vue?macro=true";
import { default as SuccessPage46UFPgmeHDMeta } from "/app/pages/Products/SuccessPage.vue?macro=true";
import { default as WriteReviewgs8TomAyX5Meta } from "/app/pages/Products/WriteReview.vue?macro=true";
import { default as indexpUrUeYrGt9Meta } from "/app/pages/Profile/Beneficiaries/index.vue?macro=true";
import { default as indexNBnl2sgmW2Meta } from "/app/pages/Profile/index.vue?macro=true";
import { default as indexw7wf9I4RT0Meta } from "/app/pages/Profile/Orders/index.vue?macro=true";
import { default as _91store_93PDyY7uyaBtMeta } from "/app/pages/Store/[store].vue?macro=true";
import { default as indexoGVG8olVvFMeta } from "/app/pages/Store/index.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "404___en",
    path: "/en/404",
    component: () => import("/app/pages/404.vue")
  },
  {
    name: "404___es",
    path: "/404",
    component: () => import("/app/pages/404.vue")
  },
  {
    name: "Admin-b2bauth-clients___en",
    path: "/en/Admin/b2bauth/clients",
    meta: clientsFlwxm4lq96Meta || {},
    component: () => import("/app/pages/Admin/b2bauth/clients.vue")
  },
  {
    name: "Admin-b2bauth-clients___es",
    path: "/Admin/b2bauth/clients",
    meta: clientsFlwxm4lq96Meta || {},
    component: () => import("/app/pages/Admin/b2bauth/clients.vue")
  },
  {
    name: "Admin-blogs-categories-id___en",
    path: "/en/Admin/blogs/categories/:id()",
    meta: _91id_93SnzDCQ1cOGMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/[id].vue")
  },
  {
    name: "Admin-blogs-categories-id___es",
    path: "/Admin/blogs/categories/:id()",
    meta: _91id_93SnzDCQ1cOGMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/[id].vue")
  },
  {
    name: "Admin-blogs-categories-create___en",
    path: "/en/Admin/blogs/categories/create",
    meta: createYRZECtAMNaMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/create.vue")
  },
  {
    name: "Admin-blogs-categories-create___es",
    path: "/Admin/blogs/categories/create",
    meta: createYRZECtAMNaMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/create.vue")
  },
  {
    name: "Admin-blogs-categories___en",
    path: "/en/Admin/blogs/categories",
    meta: indexQiYL4YKgzaMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/index.vue")
  },
  {
    name: "Admin-blogs-categories___es",
    path: "/Admin/blogs/categories",
    meta: indexQiYL4YKgzaMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/index.vue")
  },
  {
    name: "Admin-blogs-keywords-id___en",
    path: "/en/Admin/blogs/keywords/:id()",
    meta: _91id_93U2WZs7fGr8Meta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/[id].vue")
  },
  {
    name: "Admin-blogs-keywords-id___es",
    path: "/Admin/blogs/keywords/:id()",
    meta: _91id_93U2WZs7fGr8Meta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/[id].vue")
  },
  {
    name: "Admin-blogs-keywords-create___en",
    path: "/en/Admin/blogs/keywords/create",
    meta: createLlPvADp5yRMeta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/create.vue")
  },
  {
    name: "Admin-blogs-keywords-create___es",
    path: "/Admin/blogs/keywords/create",
    meta: createLlPvADp5yRMeta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/create.vue")
  },
  {
    name: "Admin-blogs-keywords___en",
    path: "/en/Admin/blogs/keywords",
    meta: indexAVX8f0bSdeMeta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/index.vue")
  },
  {
    name: "Admin-blogs-keywords___es",
    path: "/Admin/blogs/keywords",
    meta: indexAVX8f0bSdeMeta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/index.vue")
  },
  {
    name: "Admin-blogs-posts-id___en",
    path: "/en/Admin/blogs/posts/:id()",
    meta: _91id_93EX7OeoOdp0Meta || {},
    component: () => import("/app/pages/Admin/blogs/posts/[id].vue")
  },
  {
    name: "Admin-blogs-posts-id___es",
    path: "/Admin/blogs/posts/:id()",
    meta: _91id_93EX7OeoOdp0Meta || {},
    component: () => import("/app/pages/Admin/blogs/posts/[id].vue")
  },
  {
    name: "Admin-blogs-posts-create___en",
    path: "/en/Admin/blogs/posts/create",
    meta: createefcvwkDmCHMeta || {},
    component: () => import("/app/pages/Admin/blogs/posts/create.vue")
  },
  {
    name: "Admin-blogs-posts-create___es",
    path: "/Admin/blogs/posts/create",
    meta: createefcvwkDmCHMeta || {},
    component: () => import("/app/pages/Admin/blogs/posts/create.vue")
  },
  {
    name: "Admin-blogs-posts___en",
    path: "/en/Admin/blogs/posts",
    meta: indexN09f59yln3Meta || {},
    component: () => import("/app/pages/Admin/blogs/posts/index.vue")
  },
  {
    name: "Admin-blogs-posts___es",
    path: "/Admin/blogs/posts",
    meta: indexN09f59yln3Meta || {},
    component: () => import("/app/pages/Admin/blogs/posts/index.vue")
  },
  {
    name: "Admin-brands-id___en",
    path: "/en/Admin/brands/:id()",
    meta: _91id_93XdnlzqpHGSMeta || {},
    component: () => import("/app/pages/Admin/brands/[id].vue")
  },
  {
    name: "Admin-brands-id___es",
    path: "/Admin/brands/:id()",
    meta: _91id_93XdnlzqpHGSMeta || {},
    component: () => import("/app/pages/Admin/brands/[id].vue")
  },
  {
    name: "Admin-brands-create___en",
    path: "/en/Admin/brands/create",
    meta: createEPaShWGnvSMeta || {},
    component: () => import("/app/pages/Admin/brands/create.vue")
  },
  {
    name: "Admin-brands-create___es",
    path: "/Admin/brands/create",
    meta: createEPaShWGnvSMeta || {},
    component: () => import("/app/pages/Admin/brands/create.vue")
  },
  {
    name: "Admin-brands___en",
    path: "/en/Admin/brands",
    meta: indexQOmRV7n9r7Meta || {},
    component: () => import("/app/pages/Admin/brands/index.vue")
  },
  {
    name: "Admin-brands___es",
    path: "/Admin/brands",
    meta: indexQOmRV7n9r7Meta || {},
    component: () => import("/app/pages/Admin/brands/index.vue")
  },
  {
    name: "Admin-Criterias___en",
    path: "/en/Admin/Criterias",
    meta: indexstZ9jQDbVDMeta || {},
    component: () => import("/app/pages/Admin/Criterias/index.vue")
  },
  {
    name: "Admin-Criterias___es",
    path: "/Admin/Criterias",
    meta: indexstZ9jQDbVDMeta || {},
    component: () => import("/app/pages/Admin/Criterias/index.vue")
  },
  {
    name: "Admin-Dispatch-orders___en",
    path: "/en/Admin/Dispatch-orders",
    meta: indexFSIWPprlVlMeta || {},
    component: () => import("/app/pages/Admin/Dispatch-orders/index.vue")
  },
  {
    name: "Admin-Dispatch-orders___es",
    path: "/Admin/Dispatch-orders",
    meta: indexFSIWPprlVlMeta || {},
    component: () => import("/app/pages/Admin/Dispatch-orders/index.vue")
  },
  {
    name: "Admin-invoices___en",
    path: "/en/Admin/invoices",
    meta: indexokFBc4IelZMeta || {},
    component: () => import("/app/pages/Admin/invoices/index.vue")
  },
  {
    name: "Admin-invoices___es",
    path: "/Admin/invoices",
    meta: indexokFBc4IelZMeta || {},
    component: () => import("/app/pages/Admin/invoices/index.vue")
  },
  {
    name: "Admin-Managers___en",
    path: "/en/Admin/Managers",
    meta: indexVjRDbat5g2Meta || {},
    component: () => import("/app/pages/Admin/Managers/index.vue")
  },
  {
    name: "Admin-Managers___es",
    path: "/Admin/Managers",
    meta: indexVjRDbat5g2Meta || {},
    component: () => import("/app/pages/Admin/Managers/index.vue")
  },
  {
    name: "Admin-Offers-id___en",
    path: "/en/Admin/Offers/:id()",
    meta: _91id_93zjIayQjucRMeta || {},
    component: () => import("/app/pages/Admin/Offers/[id].vue")
  },
  {
    name: "Admin-Offers-id___es",
    path: "/Admin/Offers/:id()",
    meta: _91id_93zjIayQjucRMeta || {},
    component: () => import("/app/pages/Admin/Offers/[id].vue")
  },
  {
    name: "Admin-Offers-Create___en",
    path: "/en/Admin/Offers/Create",
    meta: CreateAecd1lfXy0Meta || {},
    component: () => import("/app/pages/Admin/Offers/Create.vue")
  },
  {
    name: "Admin-Offers-Create___es",
    path: "/Admin/Offers/Create",
    meta: CreateAecd1lfXy0Meta || {},
    component: () => import("/app/pages/Admin/Offers/Create.vue")
  },
  {
    name: "Admin-Offers___en",
    path: "/en/Admin/Offers",
    meta: indexORYOOkfbB0Meta || {},
    component: () => import("/app/pages/Admin/Offers/index.vue")
  },
  {
    name: "Admin-Offers___es",
    path: "/Admin/Offers",
    meta: indexORYOOkfbB0Meta || {},
    component: () => import("/app/pages/Admin/Offers/index.vue")
  },
  {
    name: "Admin-Orders___en",
    path: "/en/Admin/Orders",
    meta: indexm448lcsZVZMeta || {},
    component: () => import("/app/pages/Admin/Orders/index.vue")
  },
  {
    name: "Admin-Orders___es",
    path: "/Admin/Orders",
    meta: indexm448lcsZVZMeta || {},
    component: () => import("/app/pages/Admin/Orders/index.vue")
  },
  {
    name: "Admin-Reviews___en",
    path: "/en/Admin/Reviews",
    meta: index81ld4DjSEBMeta || {},
    component: () => import("/app/pages/Admin/Reviews/index.vue")
  },
  {
    name: "Admin-Reviews___es",
    path: "/Admin/Reviews",
    meta: index81ld4DjSEBMeta || {},
    component: () => import("/app/pages/Admin/Reviews/index.vue")
  },
  {
    name: "Admin-Roles-id___en",
    path: "/en/Admin/Roles/:id()",
    meta: _91id_93KEqMeWsWLkMeta || {},
    component: () => import("/app/pages/Admin/Roles/[id].vue")
  },
  {
    name: "Admin-Roles-id___es",
    path: "/Admin/Roles/:id()",
    meta: _91id_93KEqMeWsWLkMeta || {},
    component: () => import("/app/pages/Admin/Roles/[id].vue")
  },
  {
    name: "Admin-Roles-Create___en",
    path: "/en/Admin/Roles/Create",
    meta: CreateUzbSGGdDqWMeta || {},
    component: () => import("/app/pages/Admin/Roles/Create.vue")
  },
  {
    name: "Admin-Roles-Create___es",
    path: "/Admin/Roles/Create",
    meta: CreateUzbSGGdDqWMeta || {},
    component: () => import("/app/pages/Admin/Roles/Create.vue")
  },
  {
    name: "Admin-Roles___en",
    path: "/en/Admin/Roles",
    meta: indexLTcmbj3RapMeta || {},
    component: () => import("/app/pages/Admin/Roles/index.vue")
  },
  {
    name: "Admin-Roles___es",
    path: "/Admin/Roles",
    meta: indexLTcmbj3RapMeta || {},
    component: () => import("/app/pages/Admin/Roles/index.vue")
  },
  {
    name: "Admin-Settings-Customization___en",
    path: "/en/Admin/Settings/Customization",
    meta: CustomizationnLhrkJOvBDMeta || {},
    component: () => import("/app/pages/Admin/Settings/Customization.vue")
  },
  {
    name: "Admin-Settings-Customization___es",
    path: "/Admin/Settings/Customization",
    meta: CustomizationnLhrkJOvBDMeta || {},
    component: () => import("/app/pages/Admin/Settings/Customization.vue")
  },
  {
    name: "Admin-Settings-Generals___en",
    path: "/en/Admin/Settings/Generals",
    meta: GeneralsItpbOsixYkMeta || {},
    component: () => import("/app/pages/Admin/Settings/Generals.vue")
  },
  {
    name: "Admin-Settings-Generals___es",
    path: "/Admin/Settings/Generals",
    meta: GeneralsItpbOsixYkMeta || {},
    component: () => import("/app/pages/Admin/Settings/Generals.vue")
  },
  {
    name: "Admin-Stores-id-edit___en",
    path: "/en/Admin/Stores/:id()/edit",
    meta: editWNGxEqLYrtMeta || {},
    component: () => import("/app/pages/Admin/Stores/[id]/edit.vue")
  },
  {
    name: "Admin-Stores-id-edit___es",
    path: "/Admin/Stores/:id()/edit",
    meta: editWNGxEqLYrtMeta || {},
    component: () => import("/app/pages/Admin/Stores/[id]/edit.vue")
  },
  {
    name: "Admin-Stores-id-managers___en",
    path: "/en/Admin/Stores/:id()/managers",
    meta: managers2p6ejRqgH9Meta || {},
    component: () => import("/app/pages/Admin/Stores/[id]/managers.vue")
  },
  {
    name: "Admin-Stores-id-managers___es",
    path: "/Admin/Stores/:id()/managers",
    meta: managers2p6ejRqgH9Meta || {},
    component: () => import("/app/pages/Admin/Stores/[id]/managers.vue")
  },
  {
    name: "Admin-Stores-create___en",
    path: "/en/Admin/Stores/create",
    meta: createMzJLJjgtvUMeta || {},
    component: () => import("/app/pages/Admin/Stores/create.vue")
  },
  {
    name: "Admin-Stores-create___es",
    path: "/Admin/Stores/create",
    meta: createMzJLJjgtvUMeta || {},
    component: () => import("/app/pages/Admin/Stores/create.vue")
  },
  {
    name: "Admin-Stores___en",
    path: "/en/Admin/Stores",
    meta: indexDejcw4kpqiMeta || {},
    component: () => import("/app/pages/Admin/Stores/index.vue")
  },
  {
    name: "Admin-Stores___es",
    path: "/Admin/Stores",
    meta: indexDejcw4kpqiMeta || {},
    component: () => import("/app/pages/Admin/Stores/index.vue")
  },
  {
    name: "Admin-Stores-settings-_id___en",
    path: "/en/Admin/Stores/settings/_:id()",
    meta: __91id_93liPgmXIUrWMeta || {},
    component: () => import("/app/pages/Admin/Stores/settings/_[id].vue")
  },
  {
    name: "Admin-Stores-settings-_id___es",
    path: "/Admin/Stores/settings/_:id()",
    meta: __91id_93liPgmXIUrWMeta || {},
    component: () => import("/app/pages/Admin/Stores/settings/_[id].vue")
  },
  {
    name: "Admin-Stores-settings-customizations___en",
    path: "/en/Admin/Stores/settings/customizations",
    meta: customizationsmIotyFUuBYMeta || {},
    component: () => import("/app/pages/Admin/Stores/settings/customizations.vue")
  },
  {
    name: "Admin-Stores-settings-customizations___es",
    path: "/Admin/Stores/settings/customizations",
    meta: customizationsmIotyFUuBYMeta || {},
    component: () => import("/app/pages/Admin/Stores/settings/customizations.vue")
  },
  {
    name: "Admin-Stores-settings-notifications___en",
    path: "/en/Admin/Stores/settings/notifications",
    meta: notifications2Jr5UUnHMjMeta || {},
    component: () => import("/app/pages/Admin/Stores/settings/notifications.vue")
  },
  {
    name: "Admin-Stores-settings-notifications___es",
    path: "/Admin/Stores/settings/notifications",
    meta: notifications2Jr5UUnHMjMeta || {},
    component: () => import("/app/pages/Admin/Stores/settings/notifications.vue")
  },
  {
    name: "Admin-stripe-proxy-credentials___en",
    path: "/en/Admin/stripe-proxy/credentials",
    meta: credentialsTeOBXlbDJkMeta || {},
    component: () => import("/app/pages/Admin/stripe-proxy/credentials.vue")
  },
  {
    name: "Admin-stripe-proxy-credentials___es",
    path: "/Admin/stripe-proxy/credentials",
    meta: credentialsTeOBXlbDJkMeta || {},
    component: () => import("/app/pages/Admin/stripe-proxy/credentials.vue")
  },
  {
    name: "Admin-stripe-proxy-transactions___en",
    path: "/en/Admin/stripe-proxy/transactions",
    meta: transactionsmrO5TgV3NuMeta || {},
    component: () => import("/app/pages/Admin/stripe-proxy/transactions.vue")
  },
  {
    name: "Admin-stripe-proxy-transactions___es",
    path: "/Admin/stripe-proxy/transactions",
    meta: transactionsmrO5TgV3NuMeta || {},
    component: () => import("/app/pages/Admin/stripe-proxy/transactions.vue")
  },
  {
    name: "Admin-Suborders-suborder-show___en",
    path: "/en/Admin/Suborders/:suborder()/show",
    component: () => import("/app/pages/Admin/Suborders/[suborder]/show.vue")
  },
  {
    name: "Admin-Suborders-suborder-show___es",
    path: "/Admin/Suborders/:suborder()/show",
    component: () => import("/app/pages/Admin/Suborders/[suborder]/show.vue")
  },
  {
    name: "Admin-Suborders___en",
    path: "/en/Admin/Suborders",
    meta: indexko3TDLI7bkMeta || {},
    component: () => import("/app/pages/Admin/Suborders/index.vue")
  },
  {
    name: "Admin-Suborders___es",
    path: "/Admin/Suborders",
    meta: indexko3TDLI7bkMeta || {},
    component: () => import("/app/pages/Admin/Suborders/index.vue")
  },
  {
    name: "Admin-Users-id___en",
    path: "/en/Admin/Users/:id()",
    meta: _91id_93vDNpOuaA6pMeta || {},
    component: () => import("/app/pages/Admin/Users/[id].vue")
  },
  {
    name: "Admin-Users-id___es",
    path: "/Admin/Users/:id()",
    meta: _91id_93vDNpOuaA6pMeta || {},
    component: () => import("/app/pages/Admin/Users/[id].vue")
  },
  {
    name: "Admin-Users-create___en",
    path: "/en/Admin/Users/create",
    meta: create9E3IcyrukmMeta || {},
    component: () => import("/app/pages/Admin/Users/create.vue")
  },
  {
    name: "Admin-Users-create___es",
    path: "/Admin/Users/create",
    meta: create9E3IcyrukmMeta || {},
    component: () => import("/app/pages/Admin/Users/create.vue")
  },
  {
    name: "Admin-Users___en",
    path: "/en/Admin/Users",
    meta: indexE5cTrmppiJMeta || {},
    component: () => import("/app/pages/Admin/Users/index.vue")
  },
  {
    name: "Admin-Users___es",
    path: "/Admin/Users",
    meta: indexE5cTrmppiJMeta || {},
    component: () => import("/app/pages/Admin/Users/index.vue")
  },
  {
    name: "Auth-ConfirmPassword___en",
    path: "/en/Auth/ConfirmPassword",
    component: () => import("/app/pages/Auth/ConfirmPassword.vue")
  },
  {
    name: "Auth-ConfirmPassword___es",
    path: "/Auth/ConfirmPassword",
    component: () => import("/app/pages/Auth/ConfirmPassword.vue")
  },
  {
    name: "Auth-ForgotPassword___en",
    path: "/en/Auth/ForgotPassword",
    component: () => import("/app/pages/Auth/ForgotPassword.vue")
  },
  {
    name: "Auth-ForgotPassword___es",
    path: "/Auth/ForgotPassword",
    component: () => import("/app/pages/Auth/ForgotPassword.vue")
  },
  {
    name: "Auth-Login___en",
    path: "/en/Auth/Login",
    meta: LoginekOdCTVoFyMeta || {},
    component: () => import("/app/pages/Auth/Login.vue")
  },
  {
    name: "Auth-Login___es",
    path: "/Auth/Login",
    meta: LoginekOdCTVoFyMeta || {},
    component: () => import("/app/pages/Auth/Login.vue")
  },
  {
    name: "Auth-Register___en",
    path: "/en/Auth/Register",
    component: () => import("/app/pages/Auth/Register.vue")
  },
  {
    name: "Auth-Register___es",
    path: "/Auth/Register",
    component: () => import("/app/pages/Auth/Register.vue")
  },
  {
    name: "Auth-ResetPassword___en",
    path: "/en/Auth/ResetPassword",
    component: () => import("/app/pages/Auth/ResetPassword.vue")
  },
  {
    name: "Auth-ResetPassword___es",
    path: "/Auth/ResetPassword",
    component: () => import("/app/pages/Auth/ResetPassword.vue")
  },
  {
    name: "Auth-VerifyEmail___en",
    path: "/en/Auth/VerifyEmail",
    component: () => import("/app/pages/Auth/VerifyEmail.vue")
  },
  {
    name: "Auth-VerifyEmail___es",
    path: "/Auth/VerifyEmail",
    component: () => import("/app/pages/Auth/VerifyEmail.vue")
  },
  {
    name: "blog-blog___en",
    path: "/en/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue")
  },
  {
    name: "blog-blog___es",
    path: "/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "Cart___en",
    path: "/en/Cart",
    component: () => import("/app/pages/Cart/index.vue")
  },
  {
    name: "Cart___es",
    path: "/Cart",
    component: () => import("/app/pages/Cart/index.vue")
  },
  {
    name: "Categories-id___en",
    path: "/en/Categories/:id()",
    meta: _91id_93zd8C5QrYYyMeta || {},
    component: () => import("/app/pages/Categories/[id].vue")
  },
  {
    name: "Categories-id___es",
    path: "/Categories/:id()",
    meta: _91id_93zd8C5QrYYyMeta || {},
    component: () => import("/app/pages/Categories/[id].vue")
  },
  {
    name: "Categories-Create___en",
    path: "/en/Categories/Create",
    meta: CreatecyfxlxalP2Meta || {},
    component: () => import("/app/pages/Categories/Create.vue")
  },
  {
    name: "Categories-Create___es",
    path: "/Categories/Create",
    meta: CreatecyfxlxalP2Meta || {},
    component: () => import("/app/pages/Categories/Create.vue")
  },
  {
    name: "Categories___en",
    path: "/en/Categories",
    meta: indexuVOdZoEgx5Meta || {},
    component: () => import("/app/pages/Categories/index.vue")
  },
  {
    name: "Categories___es",
    path: "/Categories",
    meta: indexuVOdZoEgx5Meta || {},
    component: () => import("/app/pages/Categories/index.vue")
  },
  {
    name: "Contact-Us___en",
    path: "/en/Contact-Us",
    component: () => import("/app/pages/Contact-Us/index.vue")
  },
  {
    name: "Contact-Us___es",
    path: "/Contact-Us",
    component: () => import("/app/pages/Contact-Us/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "info___en",
    path: "/en/info",
    component: () => import("/app/pages/info.vue")
  },
  {
    name: "info___es",
    path: "/info",
    component: () => import("/app/pages/info.vue")
  },
  {
    name: "information-InformationPage___en",
    path: "/en/information/:InformationPage()",
    component: () => import("/app/pages/information/[InformationPage].vue")
  },
  {
    name: "information-InformationPage___es",
    path: "/information/:InformationPage()",
    component: () => import("/app/pages/information/[InformationPage].vue")
  },
  {
    name: "Offers___en",
    path: "/en/Offers",
    meta: indexOPPBcA3NliMeta || {},
    component: () => import("/app/pages/Offers/index.vue")
  },
  {
    name: "Offers___es",
    path: "/Offers",
    meta: indexOPPBcA3NliMeta || {},
    component: () => import("/app/pages/Offers/index.vue")
  },
  {
    name: "Orders-order-redirect-page___en",
    path: "/en/Orders/:order()/redirect-page",
    component: () => import("/app/pages/Orders/[order]/redirect-page.vue")
  },
  {
    name: "Orders-order-redirect-page___es",
    path: "/Orders/:order()/redirect-page",
    component: () => import("/app/pages/Orders/[order]/redirect-page.vue")
  },
  {
    name: "Orders-order-show___en",
    path: "/en/Orders/:order()/show",
    meta: showG9p1PawY1uMeta || {},
    component: () => import("/app/pages/Orders/[order]/show.vue")
  },
  {
    name: "Orders-order-show___es",
    path: "/Orders/:order()/show",
    meta: showG9p1PawY1uMeta || {},
    component: () => import("/app/pages/Orders/[order]/show.vue")
  },
  {
    name: "Orders-order-success-page___en",
    path: "/en/Orders/:order()/success-page",
    component: () => import("/app/pages/Orders/[order]/success-page.vue")
  },
  {
    name: "Orders-order-success-page___es",
    path: "/Orders/:order()/success-page",
    component: () => import("/app/pages/Orders/[order]/success-page.vue")
  },
  {
    name: "Orders-Create___en",
    path: "/en/Orders/Create",
    component: () => import("/app/pages/Orders/Create.vue")
  },
  {
    name: "Orders-Create___es",
    path: "/Orders/Create",
    component: () => import("/app/pages/Orders/Create.vue")
  },
  {
    name: "Products-product___en",
    path: "/en/Products/:product()",
    meta: _91product_93fyC6SDu9TAMeta || {},
    component: () => import("/app/pages/Products/[product].vue")
  },
  {
    name: "Products-product___es",
    path: "/Products/:product()",
    meta: _91product_93fyC6SDu9TAMeta || {},
    component: () => import("/app/pages/Products/[product].vue")
  },
  {
    name: "Products-slug-edit___en",
    path: "/en/Products/:slug()/edit",
    meta: editJpj8E8UponMeta || {},
    component: () => import("/app/pages/Products/[slug]/edit.vue")
  },
  {
    name: "Products-slug-edit___es",
    path: "/Products/:slug()/edit",
    meta: editJpj8E8UponMeta || {},
    component: () => import("/app/pages/Products/[slug]/edit.vue")
  },
  {
    name: "Products-AcceptReview___en",
    path: "/en/Products/AcceptReview",
    component: () => import("/app/pages/Products/AcceptReview.vue")
  },
  {
    name: "Products-AcceptReview___es",
    path: "/Products/AcceptReview",
    component: () => import("/app/pages/Products/AcceptReview.vue")
  },
  {
    name: "Products-Create___en",
    path: "/en/Products/Create",
    meta: CreateDQA0mG28MSMeta || {},
    component: () => import("/app/pages/Products/Create.vue")
  },
  {
    name: "Products-Create___es",
    path: "/Products/Create",
    meta: CreateDQA0mG28MSMeta || {},
    component: () => import("/app/pages/Products/Create.vue")
  },
  {
    name: "Products___en",
    path: "/en/Products",
    meta: indexTexopOjvruMeta || {},
    component: () => import("/app/pages/Products/index.vue")
  },
  {
    name: "Products___es",
    path: "/Products",
    meta: indexTexopOjvruMeta || {},
    component: () => import("/app/pages/Products/index.vue")
  },
  {
    name: "Products-ReadReview___en",
    path: "/en/Products/ReadReview",
    component: () => import("/app/pages/Products/ReadReview.vue")
  },
  {
    name: "Products-ReadReview___es",
    path: "/Products/ReadReview",
    component: () => import("/app/pages/Products/ReadReview.vue")
  },
  {
    name: "Products-Review-Read-product___en",
    path: "/en/Products/Review/Read/:product()",
    component: () => import("/app/pages/Products/Review/Read/[product].vue")
  },
  {
    name: "Products-Review-Read-product___es",
    path: "/Products/Review/Read/:product()",
    component: () => import("/app/pages/Products/Review/Read/[product].vue")
  },
  {
    name: "Products-Review-Write-product___en",
    path: "/en/Products/Review/Write/:product()",
    component: () => import("/app/pages/Products/Review/Write/[product].vue")
  },
  {
    name: "Products-Review-Write-product___es",
    path: "/Products/Review/Write/:product()",
    component: () => import("/app/pages/Products/Review/Write/[product].vue")
  },
  {
    name: "Products-SuccessPage___en",
    path: "/en/Products/SuccessPage",
    component: () => import("/app/pages/Products/SuccessPage.vue")
  },
  {
    name: "Products-SuccessPage___es",
    path: "/Products/SuccessPage",
    component: () => import("/app/pages/Products/SuccessPage.vue")
  },
  {
    name: "Products-WriteReview___en",
    path: "/en/Products/WriteReview",
    component: () => import("/app/pages/Products/WriteReview.vue")
  },
  {
    name: "Products-WriteReview___es",
    path: "/Products/WriteReview",
    component: () => import("/app/pages/Products/WriteReview.vue")
  },
  {
    name: "Profile-Beneficiaries___en",
    path: "/en/Profile/Beneficiaries",
    meta: indexpUrUeYrGt9Meta || {},
    component: () => import("/app/pages/Profile/Beneficiaries/index.vue")
  },
  {
    name: "Profile-Beneficiaries___es",
    path: "/Profile/Beneficiaries",
    meta: indexpUrUeYrGt9Meta || {},
    component: () => import("/app/pages/Profile/Beneficiaries/index.vue")
  },
  {
    name: "Profile___en",
    path: "/en/Profile",
    meta: indexNBnl2sgmW2Meta || {},
    component: () => import("/app/pages/Profile/index.vue")
  },
  {
    name: "Profile___es",
    path: "/Profile",
    meta: indexNBnl2sgmW2Meta || {},
    component: () => import("/app/pages/Profile/index.vue")
  },
  {
    name: "Profile-Orders___en",
    path: "/en/Profile/Orders",
    meta: indexw7wf9I4RT0Meta || {},
    component: () => import("/app/pages/Profile/Orders/index.vue")
  },
  {
    name: "Profile-Orders___es",
    path: "/Profile/Orders",
    meta: indexw7wf9I4RT0Meta || {},
    component: () => import("/app/pages/Profile/Orders/index.vue")
  },
  {
    name: "Store-store___en",
    path: "/en/Store/:store()",
    meta: _91store_93PDyY7uyaBtMeta || {},
    component: () => import("/app/pages/Store/[store].vue")
  },
  {
    name: "Store-store___es",
    path: "/Store/:store()",
    meta: _91store_93PDyY7uyaBtMeta || {},
    component: () => import("/app/pages/Store/[store].vue")
  },
  {
    name: "Store___en",
    path: "/en/Store",
    meta: indexoGVG8olVvFMeta || {},
    component: () => import("/app/pages/Store/index.vue")
  },
  {
    name: "Store___es",
    path: "/Store",
    meta: indexoGVG8olVvFMeta || {},
    component: () => import("/app/pages/Store/index.vue")
  }
]