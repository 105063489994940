<template>
    <q-input
        v-model="proxyValue"
        color="primary"
        label=""
        :error-message="formattedErrorMessages"
        :error="errorMessagesArray.length > 0"
        :disable="disable"
        :rules="rules"
        ref="input"
        hide-bottom-space
        class="tw-min-w-[100px] tw-rounded-[3px]"
    >
        <template #label>
            <slot name="label">
                <div class="tw-flex tw-flex-row tw-text-left">
                    <label
                        v-if="required"
                        class="tw-text-red-700 tw-font-extrabold tw-mr-1"
                        >*</label
                    >
                    {{ label }}
                </div>
            </slot>
        </template>
        <template #prepend v-if="$slots.prepend">
            <slot name="prepend" />
        </template>
        <template #append>
            <slot name="append" />
        </template>
        <template #hint v-if="$slots.hint">
            <slot name="hint" />
        </template>
        <slot name="tooltip">
            <q-tooltip v-if="required && label">
                {{
                    $t("messages.this_field") +
                    label.toLowerCase() +
                    $t("messages.field_required")
                }}
            </q-tooltip>
            <q-tooltip v-else-if="required">
                {{ $t("messages.this_field") + $t("messages.field_required") }}
            </q-tooltip>
        </slot>
    </q-input>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { QInput, type ValidationRule } from "quasar"

const props = defineProps<{
    required?: boolean
    disable?: boolean
    modelValue?: string | number
    label?: string
    errors?: string | string[]
    rules?: ValidationRule[]
}>()

const emits = defineEmits(["update:modelValue", "update:errors"])

const proxyValue = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emits("update:modelValue", val)
        emits("update:errors", null)
    },
})

const input = ref<QInput | null>(null)

const hasError = computed(() => {
    return input.value?.hasError ?? false
})

const errorMessagesArray = computed(() => {
    if (typeof props.errors === "string") {
        return [props.errors] // Convierte a array si es un string
    }
    return props.errors ? props.errors : [] // Ya es un array
})

const formattedErrorMessages = computed(() => {
    if (errorMessagesArray.value) {
        return errorMessagesArray.value.join(",")
    } else return ""
})

// const createValidationRules = (rule: (boolean | string)[] | undefined) => {
//   if (typeof rule === 'undefined') {
//     return undefined
//   } else
//   if (typeof rule === 'boolean' || typeof rule === 'string') {
//     return [rule];
//   } else {
//     return rule;
//   }
// };

// // Reglas de validación
// const validationRules = computed(() => {
//   return createValidationRules(props.rules);
// });

function validate(value?: any) {
    input.value?.validate(value)
}

const focus = () => {
    input.value?.focus()
}

defineExpose({
    validate,
    hasError,
    focus,
})
</script>
