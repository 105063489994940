import type { CustomForm } from "~/types/form"

export function useForm<T>(
    obj: Record<string, any>,
    id?: string | number,
    resource?: string
): CustomForm<T> {
    const initialObj = { ...obj }
    const objKeys = Object.keys(obj)
    const data = ref(obj)
    const errors = ref<Record<string, any>>({})
    const {
        loading: processing,
        get: fGet,
        put: fPut,
        post: fPost,
        destroy: fDestroy,
        setUrl,
    } = useOFetchCustom("")

    function setData(obj: Record<string, any>) {
        data.value = { ...initialObj, ...obj }
    }
    function setErrors(newErrors: Record<string, any>) {
        errors.value = newErrors
    }

    function clearErrors() {
        errors.value = {}
    }

    function clearError(field: string) {
        delete errors.value[field]
    }

    function reset() {
        Object.keys(obj).forEach((key) => (data.value[key] = initialObj[key]))
    }

    async function get(url: string): Promise<any> {
        setUrl(url)

        return new Promise((resolve, reject) => {
            fGet(null, {
                onSuccess: (res: any) => {
                    resolve(res)
                },
                onError: (error: any) => {
                    reject(error)
                },
            })
        })
    }

    async function post(
        url: string,
        options?: { withFiles?: boolean; additionalData: any }
    ): Promise<any> {
        setUrl(url)

        let formData =
            options && options.withFiles == true
                ? objectToFormData(data.value)
                : data.value
        if (options?.additionalData) {
            formData = { ...formData, ...options.additionalData }
        }
        return new Promise((resolve, reject) => {
            fPost(formData, {
                onSuccess: (res: any) => {
                    resolve(res)
                },
                onError: (error: any) => {
                    if (error.status == 422) {
                        errors.value = error._data.errors
                    }
                    reject(error)
                },
            })
        })
    }

    async function put(
        url: string,
        options?: { withFiles?: boolean }
    ): Promise<any> {
        setUrl(url)

        const formData =
            options && options.withFiles == true
                ? objectToFormData(data.value)
                : data.value

        return new Promise((resolve, reject) => {
            fPut(formData, {
                onSuccess: (res: any) => {
                    resolve(res)
                },
                onError: (error: any) => {
                    if (error.status == 422) {
                        errors.value = error._data.errors
                    }
                    reject(error)
                },
            })
        })
    }

    async function destroy(
        url: string,
        options?: { withFiles?: boolean }
    ): Promise<any> {
        setUrl(url)

        const formData =
            options && options.withFiles == true
                ? objectToFormData(data.value)
                : data.value

        return new Promise((resolve, reject) => {
            fDestroy(formData, {
                onSuccess: (res: any) => {
                    resolve(res)
                },
                onError: (error: any) => {
                    if (error.status == 422) {
                        errors.value = error._data.errors
                    }
                    reject(error)
                },
            })
        })
    }

    const handler = {
        get: (target: any, prop: string) => {
            if (objKeys.includes(prop)) {
                return data.value[prop]
            }

            if (prop == "errors") {
                return errors.value
            }

            if (prop == "processing") {
                return processing.value
            }

            return Reflect.get(target, prop)
        },
        set: (target: any, prop: string, value: any) => {
            if (objKeys.includes(prop)) {
                data.value[prop] = value
            }

            return Reflect.set(target, prop, value)
        },
    }

    function objectToFormData(
        obj: any,
        formData = new FormData(),
        parentKey = ""
    ) {
        if (
            !(
                typeof obj === "object" &&
                !(obj instanceof File) &&
                !(obj instanceof Blob)
            ) &&
            parentKey
        ) {
            formData.append(parentKey, obj)
        } else {
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    let value = obj[key]
                    let formKey = parentKey ? `${parentKey}[${key}]` : key
                    // Handle null or undefined
                    if (value === null || value === undefined) {
                        formData.append(formKey, "")
                    }
                    // Handle arrays
                    else if (Array.isArray(value)) {
                        value.forEach((arrayItem, index) => {
                            console.log(`${formKey}[${index}]`)
                            objectToFormData(
                                arrayItem,
                                formData,
                                `${formKey}[${index}]`
                            )
                        })
                    }
                    // Handle File or Blob
                    else if (value instanceof File || value instanceof Blob) {
                        formData.append(formKey, value)
                    }
                    // Handle objects (recursive call for nested objects)
                    else if (
                        typeof value === "object" &&
                        !(value instanceof File) &&
                        !(value instanceof Blob)
                    ) {
                        objectToFormData(value, formData, formKey)
                    }
                    // Handle booleans (convert to string 'true' or 'false')
                    else if (typeof value === "boolean") {
                        formData.append(formKey, value ? "1" : "0")
                    }
                    // Handle other data types (strings, numbers, etc.)
                    else {
                        formData.append(formKey, value)
                    }
                }
            }
        }

        return formData
    }

    onMounted(() => {
        if (id && resource) {
            get(resource).then((res) => {
                data.value = { ...initialObj, ...res.data }
            })
        }
    })

    const form = {
        data,
        errors,
        processing,
        clearErrors,
        reset,
        get,
        post,
        put,
        destroy,
        setErrors,
        clearError,
        setData,
    }

    return new Proxy(form, handler)
}
